.navbar {

  &-brand {
    padding: 0;
    text-transform: none;
    font-family: $ff-serif;
    font-size: rem(24px);
  }

  &-toggler {
    border: 0;
    outline: 0;

    &-icon {
      color: #141414;
    }
  }
  .nav-link {
    font-family: $ff-sans;
    font-weight: 600;

    &-active {
      color: #fff;
    }
  }
}