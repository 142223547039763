// Font family

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');

@font-face {
  font-family: 'Miller Display Roman';
  src: url('../fonts/MillerDisplayRoman.otf');
  src: url('../fonts/MillerDisplayRoman.otf') format('opentype');
}

$ff-serif: 'Miller Display Roman', serif;
$ff-sans: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;